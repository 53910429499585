<template>
  <div>
    <h1>{{ $t('views.solved_puzzles.h1_title') }}</h1>
    <v-alert v-if="!isAuthenticated" dense text color="info">
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('views.solved_puzzles.p_not_authenticated') }}</p>
    </v-alert>
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <div v-if="isAuthenticated">
      <p>{{ $t('views.solved_puzzles.p_text') }}</p>
      <v-alert color="warning" text>
        <template #prepend>
          <fa-icon :icon="['fal', 'exclamation-circle']" size="xl" class="ma-2 mr-4" />
        </template>
        <p class="mb-0">{{ $t('views.solved_puzzles.p_alert') }}</p>
      </v-alert>
      <Puzzles :puzzle-id="true" :stars="true" :checked="true" :solved="true" :updated-at="true" :action="true"></Puzzles>
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import BtnHome from '@/components/btn/Home'
import Puzzles from '@/components/Puzzles'
import { mapGetters } from 'vuex'

export default {
  name: 'SolvedPuzzles',
  components: {
    Adslot,
    Puzzles,
    BtnHome,
  },
  computed: {
    ...mapGetters(['user']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
  },
}
</script>

<style scoped></style>
