var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.puzzles,"options":_vm.options,"server-items-length":_vm.totalPuzzles,"loading":_vm.loading,"multi-sort":"","footer-props":{
    'items-per-page-options': [5, 10, 25],
    'items-per-page-text': _vm.$t('components.puzzles.data_table.items_per_page_text'),
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.puzzleId",fn:function(ref){
  var item = ref.item;
return [_c('hyperlink',{attrs:{"href":_vm.getHrefByPuzzleId(item.puzzleId),"aria-label":_vm.getPurposeById(item.puzzleId)}},[_vm._v(_vm._s(_vm.formatNumber(item.puzzleId)))])]}},{key:"item.stars",fn:function(ref){
  var item = ref.item;
return [_c('fa-icon',{staticClass:"yellow--text mr-1",attrs:{"icon":['fas', 'star'],"transform":"grow-8"}}),_c('span',{staticClass:"text-caption grey--text text--darken-3",staticStyle:{"margin-left":"-15px"}},[_vm._v(_vm._s(item.stars))])]}},{key:"item.progress",fn:function(ref){
  var item = ref.item;
return [_c('v-progress-linear',{staticClass:"mx-auto",staticStyle:{"width":"55px"},attrs:{"value":item.progress,"color":"teal lighten-3","height":"21"}},[_c('strong',[_vm._v(_vm._s(item.progress)+" %")])])]}},{key:"item.checked",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.checked)+" mal ")]}},{key:"item.solved",fn:function(ref){
  var item = ref.item;
return [_c('fa-icon',{directives:[{name:"show",rawName:"v-show",value:(!!item.solved),expression:"!!item.solved"}],staticClass:"green--text mr-1",attrs:{"icon":['fas', 'check'],"transform":"grow-8"}})]}},{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","fab":"","color":"transparent","elevation":"0"},on:{"click":function($event){return _vm.deleteSavedPuzzle(item.action)}}},[_c('fa-icon',{staticClass:"red--text",attrs:{"icon":['fas', 'times'],"size":"lg","transform":"grow-2"}})],1)]}}],null,true)},[_c('Confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }