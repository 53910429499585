<template>
  <v-data-table
    :headers="filteredHeaders"
    :items="puzzles"
    :options.sync="options"
    :server-items-length="totalPuzzles"
    :loading="loading"
    multi-sort
    :footer-props="{
      'items-per-page-options': [5, 10, 25],
      'items-per-page-text': $t('components.puzzles.data_table.items_per_page_text'),
    }"
  >
    <template #[`item.puzzleId`]="{ item }">
      <hyperlink :href="getHrefByPuzzleId(item.puzzleId)" :aria-label="getPurposeById(item.puzzleId)">{{
        formatNumber(item.puzzleId)
      }}</hyperlink>
    </template>
    <template #[`item.stars`]="{ item }">
      <fa-icon class="yellow--text mr-1" :icon="['fas', 'star']" transform="grow-8"></fa-icon>
      <span style="margin-left: -15px" class="text-caption grey--text text--darken-3">{{ item.stars }}</span>
    </template>
    <template #[`item.progress`]="{ item }">
      <v-progress-linear :value="item.progress" color="teal lighten-3" height="21" class="mx-auto" style="width: 55px">
        <strong>{{ item.progress }} %</strong>
      </v-progress-linear>
    </template>
    <template #[`item.checked`]="{ item }"> {{ item.checked }} mal </template>
    <template #[`item.solved`]="{ item }">
      <fa-icon v-show="!!item.solved" class="green--text mr-1" :icon="['fas', 'check']" transform="grow-8"></fa-icon>
    </template>
    <template #[`item.action`]="{ item }">
      <v-btn small fab color="transparent" elevation="0" @click="deleteSavedPuzzle(item.action)">
        <fa-icon class="red--text" :icon="['fas', 'times']" size="lg" transform="grow-2" />
      </v-btn>
    </template>
    <Confirm ref="confirm"></Confirm>
  </v-data-table>
</template>

<script>
import Hyperlink from '@/components/Hyperlink.vue'
import { Com } from '@/util'
import { mapGetters } from 'vuex'

export default {
  name: 'Puzzles',
  components: {
    Hyperlink,
  },
  props: {
    puzzleId: {
      type: Boolean,
      default: false,
    },
    stars: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    solved: {
      type: Boolean,
      default: false,
    },
    updatedAt: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalPuzzles: 0,
      puzzles: [],
      loading: true,
      options: {
        itemsPerPage: 5,
        sortBy: ['updatedAt'],
        sortDesc: [true],
      },
      headers: [
        {
          text: this.$t('components.puzzles.data_table.header.sudoku'),
          align: 'end',
          sortable: false,
          value: 'puzzleId',
        },
        { text: this.$t('components.puzzles.data_table.header.stars'), value: 'stars', align: 'center', sortable: false },
        { text: this.$t('components.puzzles.data_table.header.progress'), value: 'progress', align: 'center', sortable: false },
        { text: this.$t('components.puzzles.data_table.header.checked'), value: 'checked', align: 'center', sortable: false },
        { text: this.$t('components.puzzles.data_table.header.solved'), value: 'solved', align: 'center', sortable: false },
        { text: this.$t('components.puzzles.data_table.header.updated_at'), value: 'updatedAt', align: 'start', sortable: false },
        { text: this.$t('components.puzzles.data_table.header.action'), value: 'action', align: 'center', sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters(['user']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        if (this[header.value]) return header
      })
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isAuthenticated) this.getDataFromApi()
  },
  methods: {
    getHrefByPuzzleId(number) {
      return this.$t('hyperlinks.id.href', { number })
    },
    getPurposeById(number) {
      return this.$t('hyperlinks.id.purpose', { number: this.formatNumber(number) })
    },
    deleteSavedPuzzle: async function (puzzleId) {
      const message = this.$t(
        this.solved ? 'components.puzzles.actions.confirm.delete_solved_game' : 'components.puzzles.actions.confirm.delete_saved_game',
        {
          puzzleId: this.formatNumber(puzzleId),
        }
      )
      const res = await this.$confirm(String(message), {
        title: String(this.$t('components.puzzles.actions.confirm.title')),
        color: 'warning lighten-2',
        buttonTrueText: String(this.$t('components.puzzles.actions.confirm.delete')),
        buttonFalseText: String(this.$t('components.puzzles.actions.confirm.cancel')),
      })
      if (res) {
        this.loading = true
        const com = new Com()
        await com
          .deleteSavedPuzzle(puzzleId)
          .then(() => this.getDataFromApi())
          .finally(() => (this.loading = false))
        const sudokuStatistics = await com.getSudokuStatistics()
        await this.$store.dispatch('setSudokuStatistics', sudokuStatistics.data)
      }
    },
    getDataFromApi: function () {
      this.loading = true
      const com = new Com()
      const { page, itemsPerPage, sortBy, sortDesc } = this.options
      const requestBody = {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
        solved: this.solved,
      }
      com.getSavedPuzzles(requestBody).then((savedPuzzles) => {
        this.puzzles = savedPuzzles.data.puzzles.map((puzzle) => {
          const { puzzleId, stars, progress, checked, solved, updatedAt } = puzzle
          const [year, month, day, hour, minute] = [...updatedAt.split(',')]
          const dateTime = `${day}.${month}.${year.substring(2)} ${hour}:${minute}`
          return {
            puzzleId,
            stars,
            progress,
            checked,
            solved,
            updatedAt: dateTime,
            action: puzzleId,
          }
        })
        this.totalPuzzles = savedPuzzles.data.total
        this.loading = false
      })
    },
    formatNumber(number) {
      return new Intl.NumberFormat(this.$i18n.locale).format(number)
    },
  },
}
</script>

<style lang="scss"></style>
